import React, { useState, useEffect, useContext } from 'react';
import { graphql } from 'gatsby';
import loadable from '@loadable/component';

import { makeStyles } from '@material-ui/core/styles';
import { Container } from '@material-ui/core';

import { Hero } from '../components/General/Hero';
import { TemplatesBody } from '../components/Templates/TemplatesBody';
import { Resources } from '../components/General/Resources';
import { WaveUpSVG } from '../components/WaveSVGs/WaveUpSVG';
import { SEO } from '../components/SEO';
import { ThumbnailFeatureModal } from '../components/General/ThumbnailFeatureModal';
import FeatureThumbnailContext from '../context/FeatureThumbnailContext';

const Form = loadable(() => import('../components/ContactForm/Form'));

const useStyles = makeStyles((theme) => ({
	background: {
		background: theme.white,
	},
	relatedBackground: {
		marginBottom: '7rem',
		[theme.breakpoints.down('md')]: {
			marginBottom: '4rem',
		},
	},
	formWaveDown: {
		marginBottom: '-8rem',
		[theme.breakpoints.down('sm')]: {
			marginBottom: '-4rem',
		},
		[theme.breakpoints.down('xs')]: {
			marginBottom: '-3rem',
		},
	},
	formWaveUp: {
		marginBottom: '-8rem',
		[theme.breakpoints.down('sm')]: {
			marginBottom: '-10rem',
		},
	},
	formCont: {
		padding: '10rem 0 24rem',
		[theme.breakpoints.down('md')]: {
			padding: '10rem 0',
		},
		[theme.breakpoints.down('xs')]: {
			padding: '8rem 0',
		},
	},
}));

export default function Template({ data: { template }, location }) {
	const classes = useStyles();

	const { modalOpen, setModalOpen, selectedThumbnail } = useContext(
		FeatureThumbnailContext
	);

	//state for selected card for features
	const [selectedFeature, setSelectedFeature] = useState(null);

	useEffect(() => {
		setSelectedFeature(template.featureSection[0]?._key);
	}, []);

	//feature list modal controls
	const [featureModalOpen, setFeatureModalOpen] = useState(false);

	const {
		metaTitle,
		metaDescription,
		marketoId,
		pardotUrl,
		contactForm,
		hero,
		formBgImage,
		accentColor,
		resourceTitle,
		resources,
		_rawResourceBody,
	} = template;
	return (
		<>
			<SEO title={metaTitle} description={metaDescription} />
			<Hero hero={hero} rg wave />
			<TemplatesBody
				template={template}
				setFeatureModalOpen={setFeatureModalOpen}
			/>

			<div
				className={classes.formCont}
				style={{
					backgroundImage: `url(${formBgImage?.asset?.gatsbyImageData?.images.fallback.src})`,
					backgroundSize: 'cover',
				}}>
				{!!selectedFeature ? (
					<Form
						formId={marketoId}
						pardotUrl={pardotUrl}
						contactForm={contactForm}
						privacy
						modal={false}
						location={location}
					/>
				) : null}
			</div>
			<Container>
				<Resources
					header={resourceTitle}
					resources={resources}
					subheader={_rawResourceBody}
				/>
			</Container>
			<div>
				<WaveUpSVG height='213' width='100%' fill='#f4f8ff' />
			</div>
			<ThumbnailFeatureModal
				open={modalOpen}
				setFeatureModalOpen={setModalOpen}
				wistiaLink={selectedThumbnail}
				feature
			/>
		</>
	);
}

export const query = graphql`
	query ($slug: String!) {
		template: sanityTemplates(slug: { current: { eq: $slug } }) {
			_id
			title
			metaTitle
			metaDescription
			accentColor {
				hexValue
			}
			templatePdfType {
				type
			}
			templateIndustryType {
				type
			}
			hero {
				# ctaText
				_rawContent
				backgroundImage {
					asset {
						gatsbyImageData(placeholder: BLURRED)
					}
				}
			}
			_rawOverviewBody
			callout {
				ctaBackground {
					asset {
						gatsbyImageData(placeholder: BLURRED)
					}
				}
				ctaHeader
				ctaSubheader
				ctaText
				internalLink
				ctaLink
			}
			# featureHeader
			featureSection {
				_key
				title
				cardTitle
				cardFaIcon
				cardIconColor {
					hexValue
				}
				bodySubHeader
				bodyHeader
				bodySubHeader
				image {
					featureImage {
						asset {
							gatsbyImageData(placeholder: BLURRED)
						}
					}
				}
				videoVariant
				androidLink
				iosLink
				qbLink
				ctaText
				# svgVariant
				thumbnailVideoUrl
				_rawBodyCopy
			}
			faqTitle
			faq {
				question
				_rawAnswer
			}
			testimonialTitle
			testimonial {
				title
				header
				testimonialLogo {
					asset {
						gatsbyImageData(placeholder: BLURRED)
					}
				}
				company
				testimonialText
				nameAndTitle
				videoVariant
				image {
					asset {
						gatsbyImageData(
							fit: FILLMAX
							height: 450
							width: 775
							placeholder: BLURRED
						)
					}
				}
			}
			testimonialBackground {
				asset {
					gatsbyImageData(placeholder: BLURRED, height: 800)
				}
			}
			relatedHeader
			relatedTemplates {
				faIcon
				accentColor {
					hexValue
				}
				title
				slug {
					current
				}
			}
			resourceTitle
			_rawResourceBody
			resources {
				title
				blurb
				image {
					asset {
						gatsbyImageData(placeholder: BLURRED)
					}
				}
				ctaText
				ctaLink
			}
			contactForm {
				header
				privacyPolicy
			}
			formBgImage {
				asset {
					gatsbyImageData(placeholder: BLURRED)
				}
			}
			pardotUrl
			customerLogos {
				logo {
					asset {
						gatsbyImageData(placeholder: BLURRED)
					}
				}
				title
			}
			customerLogosHeader
		}
	}
`;
